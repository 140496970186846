<template style="padding: 0px;">
  <div class="login-wrapper">

    <el-form ref="loginForm" :model="form" :rules="rules" class="login-form ele-bg-white" size="large"
      @keyup.enter.native="doSubmit">
      <h4>{{webName}}</h4>
      <el-form-item prop="phone">
        <el-input placeholder="请输入手机号码" maxlength="11" v-model="form.phone" prefix-icon="el-icon-s-custom" clearable />
      </el-form-item>
      <el-form-item prop="code">
        <div class="code_box">
          <el-input style="width:58%;" placeholder="请输入验证码" v-model="form.code" prefix-icon="el-icon-s-comment"
            clearable />
          <div class="code_btn">
            <el-button icon="" @click="sendCode" v-show='show' style="background-color:#33cc99; border-color:#33cc99;"
              type="primary">获取验证码
            </el-button>
            <el-button icon="" v-show='!show' style="background-color:#C2C2C2; border-color:#C2C2C2;" type="info">
              {{ count }}秒后重试
            </el-button>
          </div>
        </div>
      </el-form-item>

      <div class="el-form-item">
        <el-button @click="doSubmit" :loading="loading" type="primary" class="login-btn" size="large"
          style="background-color:#33cc99; ">
          {{ loading ? '登录中' : '登录' }}
        </el-button>
      </div>

    </el-form>
    <div style="position:absolute;bottom:0px;text-align:center;width:100%;">
      <div class="bottom-logo"><img src="https://kyd.oss.livingtrip.uyl.cn/3ae02f1a8b9a580715b2f76596e17cd8.png"></div>
      <div class="login-copyright">Copyright© 2017~2023 liviningtrip.uyl.cn. All Rights Reserved. 依蓝云（北京）科技有限公司 版权所有
        ISP证：鲁B1-20190022
      </div>


    </div>
    <el-dialog :visible.sync="centerDialogVisible" width="310px" center :show-close="false" :modal="false">
      <div style="display: flex;flex-direction: column; justify-content: center;align-items: center;padding: 0 20px 20px; background: #FFF;">
        <template v-if="messagebox == 0">
          <img src="./error.png" alt="" style="width: 68px;height: 68px;border-radius: 50%;margin-bottom: 15px;">
          <div style="color: #000;">				
            <div>该IP未经许可不能访问</div>
            <div>请联系小蚂蚁团队授权!</div>
          </div>
        </template>
        <template v-if="messagebox == 1">
          <img src="./shapex.png" alt="" style="width: 68px;height: 68px;border-radius: 50%;margin-bottom: 15px;">
          <div style="color: #000;display: flex;flex-direction: column; justify-content: center;align-items: center;">				
            <div>经检测当前IP地址发生变动</div>
            <div>请重新登录!</div>
          </div>
        </template>
      </div>
    </el-dialog>
  </div>
</template>

<script>
   import Config from "@/config/setting.js"
export default {
  name: 'Login',
  data() {
    return {
      centerDialogVisible: false,
      messagebox: '',
      timer2: null,
      webName:'可蚁点SAAS政务一体化平台',
      loading: false,  // 加载状态
      form: { phone: '', code: '' },  // 表单数据
      rules: {  // 表单验证规则
        phone: [
          { required: true, message: '请输入账号', trigger: 'blur' }
        ],

        code: [
          { required: true, message: '请输入验证码', trigger: 'blur' }
        ]
      },
      captcha: '',
      // key: '',
      show: true,
      count: '',
      timer: null,
      is_send: false,
			IP:''
    }

  },
  created() {
    this.$emit('public_footer', false)
    this.webName=Config.name
    this.getCodeMsg()
  },
  mounted() {
		// this.getIpConfig()
  },
  beforeDestroy () {
      //清除定时器
      clearInterval(this.timer2);
      this.timer2 = null;
  },
  methods: {
		//获取登录IP地址
		getIpConfig() {
			// fetch(
			// 		`http://47.101.160.190:6060/driver/getip`, {	
			// 			method: 'get'
			// 		}).then(response => response.json())
			// 	.then(res => {
			// 		console.log('res', res)
			// 			localStorage.setItem('ip',res.ip)
			// 			this.IP = res.ip
			// 	})
				fetch(
						`https://tmpapi.livingtrip.uyl.cn/index.php/login/getIp`, {	
							method: 'get'
						}).then(response => response.json())
					.then(res => {
						console.log('res', res)
							if(res.code == 1 && res.data) {
								localStorage.setItem('ip',res.data)
								this.IP = res.data
							}
							
					})
		},
    /* 提交 */
    doSubmit() {
      this.$refs['loginForm'].validate((valid) => {
        if (!valid) return false
        // if (this.form.captcha.toLowerCase() !== this.text) return this.$message.error('验证码错误');
        this.loading = true
        let formData = new FormData()
        for (let key in this.form) formData.append(key, this.form[key])
        //console.log(this.form.phone)
				// this.form.ip = this.IP
				console.log('this.form',this.form)
        this.$http.post('/login/login', this.form).then((res) => {
          this.loading = false
       
          if (res.data.code === 0) {
            // this.$message({ type: 'success', message: '登录成功' })
            //ip限制
            // if(!res.data.data.allow_ip){
            //   this.centerDialogVisible = true;
            //   this.messagebox = 0;
            //   this.timer2 = setTimeout(() => {
            //     this.centerDialogVisible = false;
            //   }, 3000);
            //   return false
            // }
            this.$message.success({
              message: '登录成功',
              duration: 1000
            });
            this.$store.dispatch('user/setToken', 'Bearer ' + res.data.data.access_token).then(() => {
              //this.$router.push(this.$route.query.from || '/').catch(() => {
              this.$router.push('/').catch(() => {
              })
            })
          } else {
            // this.$message.error(res.data.msg)
            this.$message.error({
              message: res.data.msg,
              duration: 1000
            });
          }
        })
      })
    },

    sendCode() {
      //console.log('点击事件触发')
      const TIME_COUNT = 60;
      var that = this;
      if (!this.form.phone) {
        return this.$message({
          message: "请输入手机号",
          type: "error",
          duration: 1000,
        });
      }
      // //console.log(that.is_send);
      if (!that.is_send && !this.count) {
        // //console.log(11111111111111111)
        this.count = TIME_COUNT
        that.is_send = true;
        // this.timer = setInterval(() => {
        //   if (this.count > 0 && this.count <= TIME_COUNT) {

        //console.log('调用验证码发送接口')
        this.$http.post('/login/getCode', { phone: this.form.phone }).then(res => {
          if (res.data.code === 0) {
            that.timer = setInterval(() => {
              if (that.count > 0 && that.count <= TIME_COUNT) {
                that.count--;
              } else {
                clearInterval(that.timer)
                that.is_send = false;
                that.show = true
                that.timer = null
              }
            }, 1000)
            that.show = false
            // that.$message({ type: 'success', message: res.data.msg })
            return that.$message({
              message: res.data.msg,
              type: "success",
              duration: 1000,
            });

            // //console.log(that.is_send, '12312313123123131231')
          } else {
            that.is_send = false
            that.show = true
            clearInterval(that.timer)
            that.timer = null
            return that.$message({
              message: res.data.msg,
              type: "error",
              duration: 1000,
            });
            // this.$message.error(res.data.msg)
          }
        }).catch(e => {
          that.is_send = false
          that.show = true
          clearInterval(that.timer)
          that.timer = null
          // loading.close();
          return that.$message({
            message: e.message,
            type: "error",
            duration: 2000,
          });
          // this.$message.error(e.message)
        })

        // } else {
        //   this.show = true
        //   clearInterval(this.timer)
        //   this.timer = null
        // }
        // }, 1000)
      }
    },
    getCodeMsg(){
      let code = this.$route.query.code
      if(code =='1600'){
        this.centerDialogVisible = true;
        this.messagebox = 1;
        this.timer2 = setTimeout(() => {
          this.centerDialogVisible = false;
        }, 3000);
      }
    }

  }
}
</script>

<style scoped>
.el-footer {
  display: none !important;
}

.login-wrapper {
  padding: 50px 20px;
  position: relative;
  box-sizing: border-box;
  background-image: url("~@/assets/bg-login.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  min-height: 100vh;
}

.login-wrapper:before {
  content: "";
  background-color: rgba(0, 0, 0, .2);
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

.login-form {
  margin: 0 auto;
  width: 360px;
  max-width: 100%;
  padding: 25px 30px;
  position: relative;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.15);
  box-sizing: border-box;
  border-radius: 4px;
  z-index: 2;

}

.login-form h4 {
  text-align: center;
  margin: 0 0 25px 0;
}

.login-form>.el-form-item {
  margin-bottom: 25px;
}

.login-input-group {
  display: flex;
  align-items: center;
}

.login-input-group>>>.el-input {
  flex: 1;
}

.login-captcha {
  height: 38px;
  width: 102px;
  margin-left: 10px;
  border-radius: 4px;
  border: 1px solid #DCDFE6;
  text-align: center;
  cursor: pointer;
}

.login-captcha:hover {
  opacity: .75;
}

.login-btn {
  display: block;
  width: 100%;
}

.login-oauth-icon {
  color: #FFF;
  padding: 5px;
  margin: 0 10px;
  font-size: 18px;
  border-radius: 50%;
  cursor: pointer;
}

.login-copyright {
  color: #eee;
  padding-top: 20px;
  text-align: center;
  position: relative;
  z-index: 1;
}

@media screen and (min-height: 550px) {
  .login-form {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateX(-50%);
    margin-top: -250px;
  }

  .login-copyright {
    position: absolute;
    bottom: 10px;
    right: 0;
    left: 0;
  }
}

.bottom-logo {
  position: absolute;
  bottom: 40px;
  width: 100%;
  margin: 0 auto;
}

.bottom-logo img {
  width: 240px;
}

.code_box {
  position: relative;
}

.code_btn {
  position: absolute;
  right: 0;
  top: 0;
}
/deep/.el-dialog__header {
    padding-bottom: 0;
    border-bottom: 0;
}
/deep/.el-dialog .el-dialog__body{
  background: #FFF !important;
  padding: 10px 10px;
  border-radius: 15px;

}
/deep/.el-dialog {
	margin-top: 0px !important;
	position: absolute;
	top: 43%;
	left: 50%;
	transform: translate(-50%,-50%);
  border-radius: 15px;
	/* margin-top: 60px !important; */
	max-width: 1200px;
}
</style>